body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  background-color: #f9f9f9;
  font-family: Urbanist, sans-serif;
}

.webLink {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 0.9em;
}
.mobileLink {
  color: #555;
  text-decoration: none;
  font-weight: 700;
  margin-left: 10;
  margin-right: 10;
}

.menuSelect * {
  border: none !important;
  color: white !important;
}

.raw-image {
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 4px;
}

.navLinkMenu {
  color: #333;
  text-decoration: none;
  font-size: 12;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 700 !important;
}
